import React, { Component } from "react"
import {
	Layout,
	SEO,
	CaseStudies,
	HeroBanner,
	CustomersSlider,
	Image,
} from "../../components"
import { validateArray } from "../../components/utilities/functions"
import AOS from "aos"
import "./index.scss"

class WorkPage extends Component {
	componentDidMount() {
		AOS.init({
			duration: 1000,
			disable: "mobile",
			once: true,
		})
	}

	render() {
		const { pageContext } = this.props
		const { uid = "", locale = "", url, seo, hero, case_studies, customers_section, press_section } =
			pageContext.node || {};

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (
			<Layout
				className="home-wrapper animation-wrapper work-template"
				pageURL={url}
			>
				<SEO pageURL={url} {...seo} extension={extension}/>
				{hero && (
					<HeroBanner
						image={hero.hero_image}
						imageAlt={hero.image_alt}
						heading={hero.heading}
						hasContainer={true}
						hasOverlayBG={true}
					/>
				)}
				{/* <section className='bg-grey work-whatwedo-wrapper has-top-border'> */}
				{case_studies && case_studies.case_studies_list && (
					<CaseStudies
						data={case_studies.case_studies_list}
						heading={case_studies.heading}
						description={case_studies.description}
						headingClass="cs-heading title-lineover h1"
						className="box-10080"
						showThreeCards={false}
					/>
				)}
				{/* </section> */}
				{press_section && validateArray(press_section.press_list) && (
					<div className="press-section">
						<div className="container">
							{press_section.heading && (
								<h2 className="title-linethrough h1">
									<span>{press_section.heading}</span>
								</h2>
							)}
							<div className="press-list">
							{press_section.press_list.map((item, index) => (
								<div className="press-post row" key={index}>
									<div className="col-12 col-768-3 post-image">
										<a
										className="link"
										target={(item.link.external ? "_blank" : "_self")}
										href={item.link.link}>
											{(item.thumbnail?.image?.url && (
												<Image
													className="img-responsive w-100 post-img"
													data={item.thumbnail}
												/>
											)) ||
											(press_section.default_press_thumbnail?.image?.url && (
												<Image
													className="img-responsive w-100 post-img"
													data={press_section.default_press_thumbnail}
												/>
											))
											}
										</a>
									</div>
									<div className="col-12 col-768-9 post-content">
										{item.title && <h3 className="post-heading">{item.title}</h3>}
										{item.date_time && <p className="press-date">{item.date_time}</p>}

										{item.short_description && (
											<div
												dangerouslySetInnerHTML={{
													__html: item.short_description,
												}}
											/>
										)}
										{item.link?.link && (
											<a className="link"
										target={(item.link.external ? "_blank" : "_self")}
										href={item.link?.link}>
												{item.link?.title || "Read More"} &rarr;
											</a>
										)}
									</div>
								</div>
							))}
							</div>
						</div>
					</div>
				)}
				{customers_section && (
					<div className="customers-section text-center">
						<div className="container">
							{customers_section.heading && (
								<h2 className="title-lineover h1">
									{customers_section.heading}
								</h2>
							)}
							{customers_section.description && (
								<div
									dangerouslySetInnerHTML={{
										__html: customers_section.description,
									}}
								/>
							)}
							{customers_section.cta && customers_section.cta.link && (
								<a
									className="btn btn-primary btn-rounded"
									href={customers_section.cta.link}
								>
									{customers_section.cta.title}
								</a>
							)}
							{validateArray(customers_section.customer_logos) && (
								<CustomersSlider
									data={customers_section.customer_logos}
									hasHover={customers_section.has_hover}
									className="customers-slider"
									stacked
								/>
							)}
						</div>
					</div>
				)}
			</Layout>
		)
	}
}

export default WorkPage
